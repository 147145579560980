<template>
	<div class="content">
		<!-- header -->
		<div class="header ft18 blf tc">
			<div class="container-header container clear">
				<div class="nav-item tr3" :class="$route.meta.index==0?'selected':''" @click="$router.push('/')"><span class="nav-item-span">首页</span></div>
				<div class="nav-item" :class="$route.meta.index==1?'selected':''" @click="$router.push('/carTypeIntro')"><span class="nav-item-span">车型介绍</span></div>
				<div class="nav-item" :class="$route.meta.index==8?'selected':''" @click="$router.push('/vipCarInfo')"><span class="nav-item-span">VIP车型</span></div>
				<div class="nav-item logo"  @click="$router.push('/')"><img src="../assets/logo.png" width="100"></div>
				<div class="nav-item" :class="$route.meta.index==2?'selected':''" @click="$router.push('/carVideo')"><span class="nav-item-span">直播看车</span></div>
				<div class="nav-item" :class="$route.meta.index==3?'selected':''" @click="$router.push('/tools')"><span class="nav-item-span">实用工具</span></div>
				<div class="nav-item" :class="$route.meta.index==4?'selected':''">
					<el-dropdown placement="bottom">
						<span class="nav-item-span">联系我们</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item><span class="nav-item-span-inner" @click="$router.push('/contact')">联系方式</span></el-dropdown-item>
							<el-dropdown-item><span class="nav-item-span-inner" @click="$router.push('/feedback')">留言反馈</span></el-dropdown-item>
							<el-dropdown-item><span class="nav-item-span-inner" @click="$router.push('/join')">公司招聘</span></el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<!--<div class="nav-item phone"><img src="../assets/phone-icon.png" width="20"><span-->
						<!--class="ml12">{{contact.customerPhone}}</span></div>-->
			</div>
		</div>
		<!-- content -->
		<div class="content-wrap">
			<router-view></router-view>
		</div>
		<!-- footer -->
		<div class="footer ft14">
			<div class="container clear">
				<img src="../assets/logo.png" class="fl" width="160">
				<div class="tc ft16 fl nav-center">
					<div class="top-nav pb12">
						<span @click="$router.push('/')">首页</span>
						<span @click="$router.push('/carTypeIntro')">车型介绍</span>
						<span @click="$router.push('/carVideo')">直播看车</span>
						<span @click="$router.push('/news')">新闻资讯</span>
						<span @click="$router.push('/tools')">实用工具</span>
						<span @click="$router.push('/contact')">联系我们</span>
					</div>
					<!--<div class="icp ft14">{{contact.copyright}}</div>-->
					<div class="icp ft14">
						天津市鑫海达汽车贸易有限公司 版权所有 <a href="http://beian.miit.gov.cn/" target="_blank"><span style="color:#d9d9d9;">津ICP备05004339号-1</span></a>
					</div>
				</div>
				<div class="fr tc">
					<el-popover placement="bottom" width="135px" trigger="hover">
						<img src="../assets/footer-wechat-img.png" width="120" />
						<div class="wechat hover tr3" slot="reference">
							<img src="../assets/wechat-icon.png" width="26">
							<div>微信</div>
						</div>
					</el-popover>
				</div>
			</div>
		</div>
		<!-- goTop -->
		<div class="right-fix">
			<ul class="right-fix-box">
				<li>
					<el-popover placement="left" width="206px" trigger="hover">
						<div class="right-fix-box-item hover">
							<img src="../assets/phone-img-fill.png" width="20">
							<span class="ml12 ft16">{{contact.customerPhone}}</span>
						</div>
						<div class="li-item hover" slot="reference">
							<img src="../assets/phone-icon.png" width="45">
						</div>
					</el-popover>
				</li>
				<li>
					<el-popover placement="left" width="206px" trigger="hover">
						<div class="right-fix-box-item hover">
							<img src="../assets/footer-wechat-img.png" width="120">
						</div>
						<div class="li-item hover" slot="reference">
							<img src="../assets/qrcode-icon.png" width="48">
						</div>
					</el-popover>
				</li>
				<li>
					<div class="li-item hover" @click="handleCarCompare">
						<img src="../assets/jihuo-icon.png" width="48">
					</div>
				</li>
				<el-backtop :right="0">
					<li>
						<div class="li-item">
							<img src="../assets/go-top-icon.png" width="42">
						</div>
					</li>
				</el-backtop>
			</ul>
		</div>
	</div>
</template>

<script>
	import { contactInfo } from '@/api/index.js'
	export default {
		data() {
			return {
				contact:{
					name: '',
					companyTel: '',
					email: '',
					webSite: '',
					address: '',
					customerPhone: '',
					copyright: ''
				},
			}
		},
		created() {
			document.title = this.$route.meta.title;
			this.getContactInfo()
		},
		methods:{
			handleCarCompare(){
				this.$router.push({
					path: '/carTypeVs',
					query:{
						code: 0
					}
				})
			},
			getContactInfo(){
				contactInfo().then(res=>{
					if(res.code === 200){
						this.contact = {
							name: res.data.name,
							companyTel: res.data.companyTel,
							email: res.data.email,
							webSite: res.data.webSite,
							address: res.data.address,
							customerPhone: res.data.customerPhone,
							copyright: res.data.copyright
						}
					}
				})
			}
		}
	}
</script>


<style scoped>
	.header {
		height: 80px;
		background-color: #080808;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1999;
		box-sizing: border-box;
		width: 100%;
	}

	.header .container {
		width: 1200px;
		margin: 0 auto;
		height: 100%;
	}

	.header .container img {
		display: inline-block;
		vertical-align: middle;
	}

	.header .container .nav-item {
		float: left;
		line-height: 80px;
		width: calc(100% / 7);
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.nav-item-span{
		line-height: 80px;
		display: block;
		width: 100%;
		color: #FFFFFF;
		font-size: 18px;
	}
	.header .container .nav-item::after {
		transition: all .3s linear;
		background-color: #b4232f;
		position: absolute;
		height: 3px;
		content: '';
		bottom: 0;
		transform: translateY(3px);
		width: 70%;
		left: 15%;
	}

	.header .container .nav-item:hover,
	.header .container .nav-item.selected {
		color: #B4232F;
	}

	.header .container .nav-item:hover::after,
	.header .container .nav-item.selected::after {
		transform: translateY(0px);
	}

	.header .container .nav-item.logo,
	.header .container .nav-item.phone {
		color: #FFFFFF;
	}

	.header .container .nav-item.logo:hover::after,
	.header .container .nav-item.phone:hover::after {
		transform: none;
		display: none;
	}

	.footer {
		background-color: #1b1b1b;
		color: #d9d9d9;
		padding: 40px 0 40px;
		height: 42px;
	}

	.footer .nav-center {
		margin-left: 130px;
	}

	.footer .nav-center .top-nav span {
		padding: 0 15px;
		cursor: pointer;
		transition: all .3s linear;
	}

	.footer .nav-center .top-nav span:hover {
		color: #B4232F;
	}

	.footer .nav-center .icp {
		padding: 5px 0 0;
	}

	.content-wrap {
		padding-top: 80px;
	}

	.right-fix {
		position: fixed;
		bottom: 160px;
		right: 50px;
		z-index: 10;
	}

	.right-fix-box li {
		width: 60px;
		height: 60px;
		background-color: #1b1b1b;
		border-radius: 6px;
		opacity: 0.8;
		text-align: center;
		margin-top: 15px;
		line-height: 60px;
	}

	.right-fix-box li img {
		width: 30px;
		display: inline-block;
		vertical-align: middle;
	}

	.el-backtop {
		width: 60px;
		background-color: none;
		position: relative;
		top: 0;
		right: 0;
		height: 60px;
		background: none;
	}

	.header .container-header {
		width: auto;
		margin: 0 auto;
		min-width: 1170px;
		height: 100%;
		padding: 0 100px;
	}

	@media screen and (max-width: 1320px) {
		.header .container-header {
			padding: 0 15px;
			font-size: 17px;
		}
	}
	.el-dropdown-menu__item{
		line-height: 60px;
		font-size: 16px;
		display: block;
	}
	.nav-item-span-inner{
		display: block;
		width: 100%;
		height: 100%;
		padding: 0 60px;
	}
</style>
